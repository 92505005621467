<template>
	<div>
		<v-container style="display: contents">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-card-text>
						<b>Desinfeccións</b>
						</v-card-text>
						<v-container>
							<v-row style="margin: auto">
								<v-col cols="12">
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									:readonly="false"
									@onValue="onValue($event)"
								></s-scanner-qr>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</div>
			</v-row>

			<v-divider></v-divider>
			<v-row>
				<v-col cols="12">
					<s-toolbar
						label="Pallet Desinfectados ffd"
						dark
						color="#BDBDBD"
						edit
						@edit="openModalEditDisinfectedPallet()"
					>
						<template v-slot:options>
							<v-tooltip bottom="">
								<template v-slot:activator="{ on }">
									<v-btn
									v-on="on"
									text
									black 
									@click="openDialogFilterReport()"
									small
									>
										<i class="fas fa-download"></i>
									</v-btn>
								</template>
								<span>Descargar Reporte</span>
							</v-tooltip>
						</template>
					</s-toolbar>
					<v-data-table
						v-model="selectedPalletDisinfected"
						:headers="headers"
						:items="items"
						@click:row="rowSelected($event)"
						item-key="DspID"
					></v-data-table>
					<!-- <s-crud
						:config="config"
						title="Pallet Desinfectados"
						ref="crudpallets"
						height="auto"
					>
						<template v-slot:accion="{row}">
						<v-btn
							color="success"
							x-small
							@click="openModal(row)"
						>
							<i class="fas fa-play"></i>
						</v-btn>
						</template>
					</s-crud> -->
				</v-col>
			</v-row>
		</v-container>

		<v-dialog
			v-model="openDialogDisinfected"
			v-if="openDialogDisinfected"
			persistent
			transition="dialog-bottom-transition"
			width="700"
		>
			<create-disinfected-pallet
				@closeModalDisinfected="closeModalDisinfected()"
				:TypeCultive="TypeCultive"
				:pallet="pallet"
			></create-disinfected-pallet>
		</v-dialog>

		<v-dialog
			v-if="dialogFilterReport"
			v-model="dialogFilterReport"
			width="400"
		>
			
			<v-card>
				<s-toolbar
					label="Descarga Reporte"
					close
					@close="dialogFilterReport = false"
					pdf
					@pdf="printReportPdf()"
					color="#BAB6B5"
					dark
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha"
								v-model="filterReport.DateInitial"
							></s-date>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import _sPalletSend from '@/services/FrozenProduction/FrzLotProductionSelectionPalletSendService.js';
	import CreateDisinfectedPallet from "@/views/FrozenProduction/WashDisinfect/CreateDisinfectedPallet.vue";
	import _sDisinfectedPalletService from '@/services/FrozenProduction/DisinfectedPalletService';
	
	

	export default {
		components: {
			CreateDisinfectedPallet,
		},

		data() {
			return {
				selectedPalletDisinfected: [],
				configScann: {},
				pallet: {},
				openDialogDisinfected: false,
				TypeCultive: 0,
				headers: [
						{ text: "ID", value: "DspID" },
						{ text: "# Pallet", value: "LlpID" },
						{ text: "Lote Producción", value: "PrfYuliano" },
						{ text: "N° Jabas", value: "LlpQuantityJaba" },
						{ text: "Desinfectante Usado", value: "TypeDisinfectantName" },
						{ text: "Concentracion inicial (ppm)", value: "DspInitialConcentration" },
						{ text: "Repotenciación (ml)", value: "DspRepowering" },
						{ text: "Concentración final (ppm)", value: "DspFinalConcentration" },
						{ text: "Tiempo de inmersión (min)", value: "DspDiveTime" },
						{ text: "Acción correctiva/Observaciones", value: "TypeCorrelativeActionName" },
						{ text: "Nivel de agua en tina", value: "TypeWaterLevelName" }
					],
				items: [],
				dialogFilterReport: false,
				filterReport: {},
			}
		},

		methods: {
			openDialogFilterReport()
			{
				this.dialogFilterReport = true;
			},

			printReportPdf()
			{
				//this.filterReport = this.rowSelect;
				
				_sDisinfectedPalletService
				.reportpdf(this.filterReport, this.$fun.getUserID())
				.then((r) => {
					this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
				})
				.catch((e) => {
					this.$fun.alert("No hay Datos", "warning");
				});
			},

			rowSelected(item)
			{
				this.selectedPalletDisinfected = [];
				this.selectedPalletDisinfected.push(item);
				this.pallet = item;
                console.log("🚀 ~ file: DisinfectionSelection.vue ~ line 121 ~ item", item)
				this.TypeCultive = item.TypeCultive;
			},

			openModalEditDisinfectedPallet()
			{
				this.openDialogDisinfected = true;
			},
			
			onValue(val) {
				
				this.pallet = [];
				this.TypeCultive = 0;
				if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				}
				else
				{
					val = parseInt(val);
					_sPalletSend
					.searchbyid(val, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200){
							this.pallet = resp.data;
                            this.TypeCultive = this.pallet.TypeCultive;
							// if(this.TypeCultive == 3)
							// {
								this.openDialogDisinfected = true;
							// }
							// else
							// {
							// 	this.$fun.alert("Esta desinfección solo esta activo para Palta, si desea habilitar comuniquese con el Area de Sistemas", "warning");
							// }
                            
						}
					})
				}
			},

			closeModalDisinfected()
			{
				this.openDialogDisinfected = false;
				_sDisinfectedPalletService
				this.getDataPalletsDisinfected();
			},

			getDataPalletsDisinfected()
			{
				_sDisinfectedPalletService
				.paginationselection(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.items = resp.data;
                        console.log("🚀 ~ file: DisinfectionSelection.vue ~ line 170 ~ this.items", this.items)
					}
				})
			}
		},

		created () {
			this.getDataPalletsDisinfected();
		},
	}
</script>